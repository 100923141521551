@import '~antd/dist/antd.less';

.row-dragging {
  z-index: 1000;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.site-layout-content {
  min-height: 280px;
  background: #fff;
}

.ant-descriptions-item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  color: #8c8c8c;
}

.ant-descriptions-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.ant-spin-container {
  min-height: 200px;
  min-width: 100%;
}

.ant-input-number-input-wrap > input {
  text-align: right;
  padding-right: 30px;
}

.map-marker-label {
  background-color: rgba(173, 216, 230, 0.8);
  max-width: 100px;
  overflow: hidden;
  padding: 3px;
  border-radius: 5px;
}
@border-radius-base: 10px;@disabled-color: fade(#000, 75%);